import React, { useEffect, Fragment } from "react"
import ImageDarkTextRightWithButton from "./components/imageDarkTextRightWithButton"
import ImageDarkTextLeftWithButton from "./components/imageDarkTextLeftWithButton"
import FAQList from '../../general/faqsList';
import CTABusiness from "../../general/ctaBusiness"
import InnerPageHeader from "./components/innerPageHeader"
import DarkBelowFoldCards from "../../general/darkBelowFoldCards";
import { scrollToElement } from "../../../../utility/utils"
import MoreForYouComponent from "../../general/more-for-you";

import InvoicingIcon from "../../../../../assets/img/more-for-you/kuda-invoicing.inline.svg"
import KudaPOSTerminalIcon from "../../../../../assets/img/more-for-you/kuda-pos-icon.inline.svg"
import VirtualPOSIcon from '../../../../../assets/img/more-for-you/kuda-virtual-icon.inline.svg'

import SoftPOSIcon from "../../../../../assets/img/kuda-softpos.inline.svg"
import SpendMoneyIcon from '../../../../../assets/img/kuda-money-dark.inline.svg'
import TrackSoftPOSIcon from "../../../../../assets/img/kuda-tracksoftpos.inline.svg"

import EntryIllustration from "../../../../../assets/img/softpos-hero-image.svg"
import EntryIllustrationBlur from "../../../../../assets/img/business/blur/kuda-business-softpos-hero-blur.svg"

import { Img } from "react-image";

// revenue images
import exploreIllustration from "../../../../../assets/img/business/revenue/withSoftPOS-illustration.svg"

// revenue images
import RevenueImage from "../../../../../assets/img/business/revenue/revenue-illustration.svg"

// payment options images
import PaymentOptionImage from "../../../../../assets/img/business/payment_options/paymentOption-illustration.svg"

import SuperchargeImage from "../../../../../assets/img/business/superCharge-illustration.svg"

import PaymentImage from "../../../../../assets/img/business/payments/payment-illustration.svg"


const businessTopFeatures = [
  {
    icon: <SoftPOSIcon />,
    text: "Turn your smartphone into a POS with Kuda softPOS."
  },
  {
    icon: <SpendMoneyIcon />,
    text: "Give your customers multiple ways to pay you."
  },
  {
    icon: <TrackSoftPOSIcon />,
    text: `Track softPOS transactions wherever you are.`
  },
]

const entryContent = {
  title: (<span>
    Turn any smartphone into a POS with
    <span className="color-secondary"> softPOS </span> on Kuda Business
  </span>),
  subtitle: "Open a Kuda Business account and receive business payments wherever you are.",
  name: "Open a Kuda Business Account",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
}

const explore = {
  title: "Move your business forward with softPOS",
  subtitle: "Business can find you anywhere. softPOS from Kuda helps you receive payments on the move with any smartphone connected to the internet.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Explore softPOS",
  illustration: (
    <Img src={exploreIllustration} className="kuda-business-image" />
  ),
}

const revenue = {
  title: "Keep an eye on your revenue",
  subtitle: "You can track all softPOS transactions in real-time on your Kuda Business mobile app for Android and iOS.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Download Kuda Business",
  illustration: (
    <Img src={RevenueImage} className="kuda-business-image" />

  ),
}

const superChargeRevenue = {
  title: "Supercharge your sales with Sales Mode.",
  subtitle: "Assign devices with softPOS to your staff and track sales easily wherever you are.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Try Sales Mode now",
  illustration: (
    <Img src={SuperchargeImage} className="kuda-business-image" />
  ),
}


const paymentOptions = {
  title: "Give your customers more ways to pay you",
  subtitle: "softPOS gives you several payment options including transfers, Pay with Kuda, QR codes, USSD and cards.",
  url: "https://kudabusiness.onelink.me/epwz/jceed731/",
  name: "Explore softPOS",
  illustration: (
    <Img src={PaymentOptionImage} className="kuda-business-image" />
  ),
}

const payments = {
  title: "Receive payments on the move with softPOS on Kuda Business",
  subtitle: "Open a Kuda Business account and turn your phone into a POS.",
  url: "",
  name: "",
  illustration: (
    <Img src={PaymentImage} className="kuda-business-image" />
  ),
}

const questions = [
  {
    heading: "How do I get Kuda softPOS?",
    list: (
      <span className="flex flex-column mt-2">
        <span className="faq-description f-16">You'll request access to softPOS on your Kuda Business mobile app. It's very easy.</span>

      </span>),
    index: 1
  },
  {
    heading: "How much do I have to pay to use softPOS?",
    list: (
      <span className="flex flex-column mt-2">
        <span className="faq-description f-16">Accessing the feature is free but you’ll pay a fee for transactions.</span>

      </span>),
    index: 2
  },
  {
    heading: (<div className="pr-5">
      How long will it take for my account to be credited after a softPOS transaction?
    </div>
    ),
    list: (
      <div className="flex flex-column mt-2">
        <span className="faq-description f-16">We'll credit your account immediately.

        </span>

      </div>),
    index: 3
  },
  {
    heading: "What kind of phone do I need to accept card payments with softPOS?",
    list: (
      <div className="flex flex-column mt-2">
        <span className="faq-description f-16">You need an NFC-enabled iPhone or Android phone.
        </span>

      </div>),
    index: 4
  },
  {
    heading: "What payment options are on softPOS?",
    list: (
      <div className="flex flex-column mt-2">
        <div className="faq-description f-16">Pay With Kuda, Pay With USSD, and Pay With Card.
        </div>

      </div>),
    index: 5
  },
  {
    heading: "What are the transaction fees for using softPOS?",
    list: (
      <div className="flex flex-column mt-2">

        <span className="pt-3 career-message-margin-bottom">
          <span className="faq-description mb-3 flex f-16">  For Pay With Kuda transactions, we'll charge you 0.1% of the transaction amount. This transaction fee is capped at ₦100.
          </span>

          <span className="faq-description mb-3 flex f-16">  For Pay With USSD transactions, we'll charge you 1.5% of the transaction amount. This transaction fee is capped at ₦2,400.
          </span>

          <span className="faq-description mb-3 flex f-16">  For Pay With Card transactions, we'll charge you 0.5% of the transaction amount. This transaction fee is capped at ₦1,000.
          </span>

        </span>
      </div>),
    index: 6
  },
]

const moreForYou = [

  {
    icon: <KudaPOSTerminalIcon />,
    title: "POS",
    subText: `Buy a physical POS, lease it for a 
    one-time fee, or get a virtual POS to accept payments quickly.`,
    linkTo: `/en-ng/business/pos/`
  },

  {
    icon: <InvoicingIcon />,
    title: "Invoicing",
    subText: `Create and manage invoices easily, and sync your product catalogue for accurate pricing.`,
    linkTo: `/en-ng/business/invoicing/`
  },
  {
    icon: <VirtualPOSIcon />,
    title: "Virtual POS",
    subText: `Empower your salespeople to accept payments instantly at all your business locations.`,
    linkTo: `/en-ng/business/virtual-pos/`
  },

]

const SoftPOS = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])
  return (
    <Fragment>
      <InnerPageHeader
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        buttonName={entryContent.name}
        buttonUrl={entryContent.url}
        entryIllustration={EntryIllustration}
        fallback={<Img src={EntryIllustrationBlur} className="hero-illustration" />}
      />

      <DarkBelowFoldCards topFeatures={businessTopFeatures} />

      <div className="softpos-sections">
        <ImageDarkTextLeftWithButton
          title={explore.title}
          subtitle={explore.subtitle}
          illustration={explore.illustration}
          name={explore.name}
          url={explore.url}
          isExternal={true}
        />


        <ImageDarkTextRightWithButton
          title={revenue.title}
          subtitle={revenue.subtitle}
          illustration={revenue.illustration}
          name={revenue.name}
          url={revenue.url}
          isExternal={true}
        />
        <ImageDarkTextLeftWithButton
          title={paymentOptions.title}
          subtitle={paymentOptions.subtitle}
          illustration={paymentOptions.illustration}
          name={paymentOptions.name}
          url={paymentOptions.url}
          isExternal={true}
        />
        <ImageDarkTextRightWithButton
          title={superChargeRevenue.title}
          subtitle={superChargeRevenue.subtitle}
          illustration={superChargeRevenue.illustration}
          name={superChargeRevenue.name}
          url={superChargeRevenue.url}
          isExternal={true}
        />
        <ImageDarkTextLeftWithButton
          title={payments.title}
          subtitle={payments.subtitle}
          illustration={payments.illustration}
          name={payments.name}
          url={payments.url}
          isExternal={true}
        />

        <FAQList title={"softPOS FAQS"} questions={questions} />
        <MoreForYouComponent moreForYou={moreForYou} />
        <CTABusiness />
      </div>

    </Fragment>
  )
}

export default SoftPOS
